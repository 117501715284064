* {
  /* Fixed Colors */
  --white-color: #FFFFFF;
  --dark-blue-color: #11467A;
  --light-blue-color: #f1f8ff;
  --blue-color: #cbddf0;

  --darker-blue-color: #03274A;
  --hover-blue-color: #2c73bb;
  --active-blue-color: #51a4f7;

  --dark-medium-blue-color: #05256b;
  --hover-medium-blue-color: #0f3483;
  --active-medium-blue-color: #1b4194;

  --green-color: #0F821B;
  --hover-green-color: #149921;
  --active-green-color: #17af26;

  --grey-color: #6C747C;

  margin: 0px;
  padding: 0px;
  font-size: 20px;
  max-width: 100vw;
}

body::-webkit-scrollbar {
  width: 0;
  background: transparent;
}