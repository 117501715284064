.medias {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    gap: 2rem;
    padding: 1rem;
}

.medias a {
    color: var(--dark-blue-color);
}

.medias img {
    width: 25px;
    height: 25px;
    padding: 0;
}

.medias img:hover {
    opacity: 80%;
}

.medias img:active {
    opacity: 60%;
}

.textWhats:hover {
    color: #25d366;
}

.textInsta:hover {
    color: #C13584;
}