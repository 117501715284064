.section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding: 2rem 10rem;
    background-color: var(--light-blue-color);
}

.section img {
    width: auto;
    justify-content: center;
    height: 40vh;
}

.section div {
    width: 50%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.section h2 {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    color: var(--dark-blue-color);
}

.section hr {
    width: 4rem;
    border: solid 2px var(--green-color);
    align-self: center;
}

.section h3 {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: justify;
    color: var(--darker-blue-color)
}

@media (max-width: 1600px) {
    .section {
        padding: 1rem;
    }

    .section div {
        width: auto;
        padding-inline: 3rem !important;
        display: flex;
        align-items: center;
    }

    .section h2 {
        font-size: 1.6rem;
        font-weight: 600;
        text-align: center;
        color: var(--dark-blue-color);
    }

    .section img {
        width: auto;
    }

}

@media (min-width: 1601px) {
    .section {
        flex-wrap: nowrap;
    }
}