.header {
    position: sticky;
    top: 0;
    z-index: 100;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
    justify-content: space-around;
    align-items: center;
    row-gap: 1rem;
    
    background-color: var(--white-color);

    border-bottom: solid 0.25px var(--dark-blue-color);
}

.header-img {
    position: initial;
    width: 100px;
    height: 100px;
    border: none;
}

@media (max-width: 1000px) {
    .header {
        gap: 0.25rem
    }
    
    .header-img {
        width: 50px;
        height: 50px;
    }
}