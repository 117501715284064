.sucessSection  {
    background-color: var(--light-blue-color);
    padding: 2rem 0rem;
    gap: 1rem;
}

.sucessSection hr {
    width: 4rem;
    border: solid 2px var(--green-color);
    margin-bottom: 20px;
    align-self: center;
}

.title  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem
}

.title  h2{
    font-size: 2rem;
    font-weight: 600;
    color: var(--dark-blue-color);
    align-items: center;
}