.section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    padding-block: 2rem;
}

.section img {
    width:10%;
    height: 15%;
}

.section div {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
}

.section h2 {
    font-size: 2rem;
    font-weight: 600;
    align-self: center;
    text-align: center;
    color: var(--dark-blue-color);
}

.section hr {
    width: 4rem;
    border: solid 2px var(--green-color);
    align-self: center;
}

.section h3 {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: justify;
    color: var(--darker-blue-color)
}

.section p {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: justify;
    color: var(--grey-color)
}

@media (max-width: 1000px) {
    .section {
        padding: 1rem;
    }

    .section div {
        width: 100vw;
        padding-inline: 3rem !important;
    }

    .section img {
        width: 20%;
    }

}

@media (min-width: 1001px) {
    .section {
        flex-wrap: nowrap;
    }
}