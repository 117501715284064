.section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
    padding: 2rem 5rem;
}

.section img {
    width: auto;
    align-self: center;
    justify-content: center;
    height: 50vh;
}

.section div {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
}

.title2 {
    display: none;
}

.title3 {
    display: none;
}


.section h2 {
    font-size: 2rem;
    font-weight: 600;
    text-align: left;
    color: var(--dark-blue-color);
}

.section hr {
    width: 4rem;
    border: solid 2px var(--green-color);
}

.section h3 {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: justify;
    color: var(--darker-blue-color)
}

.section p {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: justify;
    color: var(--grey-color)
}

@media (max-width: 1000px) {
    .section {
        padding: 1rem;
        justify-content: center;
    }

    .section div {
        width: 100vw;
        padding-inline: 3rem !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .section h2 {
        font-size: 1.6rem;
        font-weight: 600;
        text-align: center;
        color: var(--dark-blue-color);
    }

    .tiltle {
        display: none;
    }

    .title2 {
        display: block;
    }
    .title3 {
        display: block;
    }
    
    .section img {
        width: auto;

    }

}


@media (min-width: 1001px) {
    .section {
        flex-wrap: nowrap;
    }
}