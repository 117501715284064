.partners {
    overflow: hidden;
    background-color: var(--white-color);
    padding: 5rem 0;
    white-space: nowrap; 
    position: relative;
}

.partners div {
    display: inline-block;
    animation: loop 50s linear infinite ;
}

.partners div:hover {
    animation-play-state: paused;
}

.partners img {
    height: 5rem;
    margin: 0rem 5rem;
}

@keyframes loop {
    from {
        transform: translateX(0);
    }
    to {
        transform: translate(-850%);
    }
}

@media (max-width: 1000px) {
    .partners div  {
        animation: loop 65s linear infinite ;
    }

    @keyframes loop {
        from {
            transform: translateX(0);
        }
        to {
            transform: translate(-5200%);
        }
    }
}