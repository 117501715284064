

.faq h2 {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    color: var(--dark-blue-color);
    align-self: center;
    margin-bottom: 10px; /* Ajuste o valor conforme necessário */
}

.faq hr {
    width: 4rem;
    border: solid 2px var(--green-color);
    align-self: center;
    margin-bottom: 20px; /* Ajuste o valor conforme necessário */

}

.faq {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 10rem;
    gap: 1rem;
    background-color: var(--white-color);
}

.faq h3 {
    font-size: 1rem;
    font-weight: 600;
}

.faq h4 {
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    color: var(--black);
    align-self: center;
}

@media (max-width: 1000px) {
    .faq {
        padding: 1rem 2rem;

    }
}