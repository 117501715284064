.banner {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    padding-block: 1rem;
    padding-left: 3rem;
    padding-right: 0.5rem;
    min-height: 50vh;
    background-image: url(banner.jpg); 
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
}

.bannerTitle {
    width: fit-content;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0.7rem;
    border-radius: 1rem;
}


.banner h1 {
    max-width: 50rem;
    padding-right: calc(100vw - 70rem);

    font-size: 4rem;
    font-weight: 600;
    line-height: 5rem;
    text-align: left;
    color: var(--white-color);
}

.banner p {
    max-width: 50rem;
    padding-right: calc(100vw - 70rem);
    font-size: 1.3rem;
    line-height: 1.5rem;
    text-align: left;
    color: var(--white-color)
}

.banner button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    max-width: 15rem;
    height: 48px;

    cursor: pointer;

    margin-top: 1rem;
    padding: 0.5rem 2rem;
    border-radius: 0.25rem;
    background-color: var(--dark-medium-blue-color);
    border: transparent;

    color: var(--white-color);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: center;
}

.banner button:hover {
    background-color: var(--active-medium-blue-color);
}

.banner button:active {
    background-color: var(--hover-medium-blue-color);
}



@media (max-width: 1000px) {
    .banner h1 {
        max-width: 50rem;
        padding-right: calc(100vw - 70rem);
        font-size: 3rem;
        font-weight: 600;
        line-height: 5rem;
        text-align: center;
        color: var(--white-color);
    }

    .bannerTitle {
        margin: 0 auto;
    }

    .banner p {
        text-align: center;
    }

    .banner {
        background-size: cover;
        align-items: center;
        
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

}