.pages{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-inline: 1rem;
    gap: 1.8rem 3.1rem;
}

.pages a {
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: var(--green-color);
}

.pages a:hover {
    color: var(--hover-green-color);
}

.pages a:active {
    color: var(--active-green-color);
}

@media (max-width: 1000px) {
    .pages {
        gap: 0.5rem;
        padding: 0.25rem 1rem;
    }
}

