.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;

    border: 2px solid var(--dark-blue-color);
    border-radius: 1.5rem;
    padding-block: 1rem;
    gap: 1rem;
}

.container h1 {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--dark-blue-color);
}

.container p {
    font-size: 1rem;
    font-weight: 500;
    color: var(--grey-color);
}

@media (max-width: 1000px) {
    .container p {
        font-size: 0.8rem;
    }
}