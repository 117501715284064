.section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 10rem;
}

.section img {
    width: 17%;
}

.section div {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
}

.container {
    display: flex;
    flex-wrap: wrap;
}

.text {
    display: flex;
    flex-direction: column;
    padding-left: 1.5rem;
}

.section h2 {
    font-size: 2rem;
    font-weight: 600;
    align-self: center;
    color: var(--dark-blue-color);
}

.titles {
    display: flex;
    flex-direction: column;
}

.section h3 {
    font-size: 1.6rem;
    font-weight: 600;
    align-self: center;
}

.section a {
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--dark-blue-color);
    cursor: pointer;
    text-decoration: none;
}

.section hr {
    width: 4rem;
    border: solid 2px var(--green-color);
    align-self: center;
}

.text p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1rem;
    color: var(--dark-blue-color);
    display: flex;
    align-items: center;
    gap: 0.2rem;
}

.textWhats:hover {
    color: #25d366;
}

.textInsta:hover {
    color: #C13584;
}

@media (max-width: 1000px) {
    .section {
        padding: 0.8rem;
    }

    .section div {
        padding-inline: 2.5rem !important;
    }

    .section img {
        width: 50%;
    }

}

@media (min-width: 1001px) {
    .section {
        flex-wrap: nowrap;
    }
}