.footer {
    position: relative;
    bottom: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    min-height: 7rem;
    gap: 2rem;
}

.footerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.footerContent h2 {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5rem;
    color: var(--dark-blue-color);
}

.footerContent p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1rem;
    color: var(--dark-blue-color);
    display: flex;
    align-items: center;
    gap: 0.2rem;
}

.footerContent .cnpj {
    font-size: 1rem;
    font-weight: 1000;
    line-height: 1rem;
    color: var(--dark-blue-color);
    gap: 0.2rem;
}

@media (max-width: 1000px) {
    .footer {
        padding: 1rem 1rem;
    }

    .footerContent p {
        padding-inline: 2rem;
    }

    .footerContent p {
        font-size: 0.75rem;
        line-height: 1rem;
        text-align: left;    
        color: var(--dark-blue-color);
    }
}

@media (min-width: 1001px) {
    .footer {
        padding: 1rem 5rem;
    }

}